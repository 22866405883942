/* .footer_container_fluid{
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(../../images/footer/footer1.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
} */
.footer_title{
    font-weight: 800;
    opacity: 0.9;
    font-family: 'Baloo 2', cursive;
}
ul,li{
    color: rgb(0, 0, 0);
    padding: 0px;
    font-size: 14px;
    font-weight: 700;
    opacity: 0.7;
    font-family: 'Baloo 2', cursive;
}
li{
    list-style: none;
}
.social_icon{
    font-size: 25px;
}
.icon_header{
    opacity: 0.7;
    font-size: 18px;
}
.map{
    width: 100%;
    height: 150px;
}