
.carousel_box{
    width: 400px;
    height: 200px;
}
.carousel_box_img{
    width: 100%;;
    height: 100%;
    object-fit: cover;
}
.box_container{
    margin-top: 10px;
}
.carousel-indicators button{
    display: none;
}