.carousel_img{
    width: 100%;
    height: 400px;
    overflow: hidden;
}
.carousel_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.carousel-control-next, .carousel-control-prev{
    display: none !important;
}