.copyright{
    background-color: rgb(95 95 95);
}
.footer_copyright_text{
    color: #a3a3a3;
    text-align: center;
    font-family: 'Baloo 2', cursive;
    padding-top: 15px;
    padding-bottom: 15px;
    opacity: 0.7;
}
.footer_right{
    text-align: right;
}