.interest_container{
    border: 2px solid darkgrey;
    padding-left: 464px;
    padding-top: 30px;
    padding-bottom: 30px;

    /* background: url('https://ghumfir003.pythonanywhere.com/images/logo.png') no-repeat no-repeat center ; */
}
.interest_data{
    color: #544b4b;
    font-size: 29px;
    font-weight: 700;
    font-family: 'Baloo 2';
}
.interest_box{
    margin-left: 320px;
}
input[type='checkbox']{
    background-color: rgb(245, 245, 245);
    text-align: center;
    border: 2px solid rgb(97, 97, 97);
    border-radius: 100px;

}
.interest_title{
    font-family: 'Baloo 2';
    color: #544b4b;
    margin-right: 120px;
    margin-bottom: 40px;
}
/* .interest_location{
    
} */