.overflow{
    width: 100%;
    height: 200px;
    overflow: hidden;
}
.vehicle_card{
    background-color: rgb(216, 216, 216) !important;
}

.vehicle_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.vehicle_header{
    font-size: 2.4rem;
    /* font-weight: 700; */
    font-family: 'Lobster', cursive;
    color: #000;
    opacity: 0.8;
}