.form_input_field {
  margin: 10px;
}

.img_div_kyc {
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.img_kyc {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.kyc_container {
  box-shadow: 2px 0px 8px 2px grey;
  background: rgb(75, 180, 58);
  background: linear-gradient(
    90deg,
    rgba(75, 180, 58, 1) 1%,
    rgba(29, 253, 198, 1) 100%,
    rgba(69, 252, 84, 1) 100%
  );
}

.form_input_div {
  width: 50%;
  margin: auto;
}

.ghumfir_kyc_title {
  font-size: 40px;
  font-weight: 700;
  padding-top: 30px;
  /* font-family: "Baloo Tamma2"; */
  color: #6a6a6a;
}
.kyc_user_detail {
  font-size: 30px;
  color: #6a6a6a;
}

.user_kyc_profession {
  font-size: 30px;
  color: #6a6a6a;
}

.user_kyc_document_details {
  font-size: 30px;
  color: #6a6a6a;
}

.user_kyc_document_photos {
  font-size: 30px;
  color: #6a6a6a;
}

.custom_form_btn_div {
  width: 50%;
  margin: auto;
}
.custom_kyc_btn {
  margin-left: 30%;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid grey !important;
  border-radius: 50px !important;
  background: rgb(240,47,219) !important;
  background: linear-gradient(90deg, rgba(240,47,219,1) 10%, rgba(58,64,180,1) 100%, rgba(29,41,253,1) 100%) !important;
  
}
.custom_kyc_link {
  margin-left: 15px;
  text-decoration: none;
  padding: 8px;
  border-radius: 50px;
  color: black;
  background: rgb(151, 180, 58) !important;
  background: linear-gradient(
    90deg,
    rgba(151, 180, 58, 1) 1%,
    rgba(29, 41, 253, 1) 100%,
    rgba(240, 218, 47, 1) 100%
  ) !important;
}

.custom_kyc_link:hover{
    color: blanchedalmond;
}

input[type="text"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}
input[type="number"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}
input[type="date"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}
input[type="file"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}
