
/* Styling Body Section  */

.body_bg{
    background: linear-gradient(rgba(255, 251, 251, 0.5), rgba(255, 246, 246, 0.5)), url('../../images/Pattern/pattern_product_bg.jpg') ;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Styling Header Section  */

.custom_login_btn{
    text-align: right;
}
.header_logo_div{
    text-align: left;
}
/* .header_logo{
    
} */
.header_login_btn{
    text-align: right;
    width: 110px !important;
}
.header_signup_btn{
    text-align: left;
    width: 110px !important;
}

.custom_logo_img_div{
    width: 100%;
    height: 100px;
}

.custom_logo_img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    text-align: right;
}