.user_img_div{
    width: 100%;
    height: 100px;
}
.user_img{
    width: 100px;
    height: 100px;
    border-radius: 100px;
}

.matchuser_img_div{
    width: 100%;
    height: 100px;
    overflow: hidden;
}

.matchuser_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 200px;
}

.interested_user{
    font-size: 1.2rem;
    font-weight: 600;
    opacity: 0.8;
    color: rgb(56 55 55);
    font-family: 'Baloo Tamma 2', cursive;
}

.userName{
    font-size: 1.4rem;
    font-weight: 600;
    opacity: 0.9;
}

.user_profile_name{
    font-family: 'Baloo Tamma 2', cursive;
}