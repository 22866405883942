@import url("//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css");
.login-block {
  background: #de6262; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #ffb88c,
    #de6262
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient( to bottom, #ffffff, #1b1b1b );
  float: left;
  width: 100%;
  
}
.banner-sec {
  /* background: url(https://static.pexels.com/photos/33972/pexels-photo.jpg)
    no-repeat left bottom; */
  background-size: cover;
  min-height: 500px;
  border-radius: 0 10px 10px 0;
  padding: 0;
}

F .carousel-inner {
  border-radius: 0 10px 10px 0;
}
.carousel-caption {
  text-align: left;
  left: 5%;
}
.login-sec {
  padding: 50px 30px;
  position: relative;
}
.login-sec .copy-text {
  position: absolute;
  width: 80%;
  bottom: 20px;
  font-size: 13px;
  text-align: center;
}
.login-sec .copy-text i {
  color: #feb58a;
}
.login-sec .copy-text a {
  color: #e36262;
}
.login-sec h2 {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 30px;
  color: #8c8c8c;
}
.login-sec h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #909090;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}
.btn-login {
  background: #626262;
  color: #fff;
  font-weight: 600;
  width: 130px;
}
.banner-text {
  width: 70%;
  position: absolute;
  bottom: 40px;
  padding-left: 20px;
}
.banner-text h2 {
  color: #fff;
  font-weight: 600;
}
.banner-text h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #fff;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
}
.banner-text p {
  color: #fff;
}

.login_banner_div{
    width: 100%;
    height: 800px;
}
.login_banner_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.welcome_heading{
  font-weight: 700;
  color: dimgrey;
  font-family: 'Lobster', cursive;
}

.new_signup{
  font-size: 25px;
  color: dimgray;
  font-weight: bolder;
}

.powered_text{
  font-size: 30px;
  color: #1b1b1b;
  margin-top: 100px;
  font-weight: bolder;
  font-family: 'Baloo Tamma 2', cursive;
}