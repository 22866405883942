@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);

.pa{
    padding: 10px;
}
.pa_t{
    padding-top: 40px;
}
.pa_r{
    padding-right: 40px;
}
.pa_tb{
    padding-top: 40px;
    padding-bottom: 40px;
}
.pa_l{
    padding-left: 10px;
}
.ma{
    margin: 10px;
}
.ma_t{
    margin-top: 10px;
}
.ma_b{
    margin-bottom: 40px;
}
.ma_l{
    margin-left: 10px;
}
.ma_r{
    margin-right: 10px;
}
.ma_tb{
    margin-top: 40px;
    margin-bottom: 40px;
}

.wc{
    color: #fff;
}
.bc{
    color: #000;
}
.tc{
    text-align: center;
}
.fs{
    font-size: 18px;
}
.copyright{
    background-color: rgb(95 95 95);
}
.footer_copyright_text{
    color: #a3a3a3;
    text-align: center;
    font-family: 'Baloo 2', cursive;
    padding-top: 15px;
    padding-bottom: 15px;
    opacity: 0.7;
}
.footer_right{
    text-align: right;
}
/* .footer_container_fluid{
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(../../images/footer/footer1.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
} */
.footer_title{
    font-weight: 800;
    opacity: 0.9;
    font-family: 'Baloo 2', cursive;
}
ul,li{
    color: rgb(0, 0, 0);
    padding: 0px;
    font-size: 14px;
    font-weight: 700;
    opacity: 0.7;
    font-family: 'Baloo 2', cursive;
}
li{
    list-style: none;
}
.social_icon{
    font-size: 25px;
}
.icon_header{
    opacity: 0.7;
    font-size: 18px;
}
.map{
    width: 100%;
    height: 150px;
}

/* Styling Body Section  */

.body_bg{
    background: linear-gradient(rgba(255, 251, 251, 0.5), rgba(255, 246, 246, 0.5)), url(/static/media/pattern_product_bg.a7949bf5.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Styling Header Section  */

.custom_login_btn{
    text-align: right;
}
.header_logo_div{
    text-align: left;
}
/* .header_logo{
    
} */
.header_login_btn{
    text-align: right;
    width: 110px !important;
}
.header_signup_btn{
    text-align: left;
    width: 110px !important;
}

.custom_logo_img_div{
    width: 100%;
    height: 100px;
}

.custom_logo_img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    text-align: right;
}
.carousel_img{
    width: 100%;
    height: 400px;
    overflow: hidden;
}
.carousel_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.carousel-control-next, .carousel-control-prev{
    display: none !important;
}

.carousel_box{
    width: 400px;
    height: 200px;
}
.carousel_box_img{
    width: 100%;;
    height: 100%;
    object-fit: cover;
}
.box_container{
    margin-top: 10px;
}
.carousel-indicators button{
    display: none;
}
.overflow{
    width: 100%;
    height: 200px;
    overflow: hidden;
}
.hotel_card{
    background-color: rgb(216, 216, 216) !important;
}

.hotel_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hotel_header{
    font-size: 2.4rem;
    /* font-weight: 700; */
    font-family: 'Lobster', cursive;
    color: #000;
    opacity: 0.8;
}
.overflow{
    width: 100%;
    height: 200px;
    overflow: hidden;
}
.place_card{
    background-color: rgb(216, 216, 216) !important;
}

.places_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.place_header{
    font-size: 2.4rem;
    /* font-weight: 700; */
    font-family: 'Lobster', cursive;
    color: #000;
    opacity: 0.8;
}
.overflow{
    width: 100%;
    height: 200px;
    overflow: hidden;
}
.vehicle_card{
    background-color: rgb(216, 216, 216) !important;
}

.vehicle_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.vehicle_header{
    font-size: 2.4rem;
    /* font-weight: 700; */
    font-family: 'Lobster', cursive;
    color: #000;
    opacity: 0.8;
}
/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */
/* .home_container_fluid{
    padding-top: 0px;
}
.home_container{
}
.home_nav_icon{
    text-align: left;
    font-family: 'Lobster', cursive;
    font-size: 45px;
    color: rgb(68, 68, 68);
}

.home_login{
    text-align: right;
    width: 110px !important;
}
.header_login_btn{
    text-align: right;
}
.home_bg{
    background: linear-gradient(rgba(225,225,225,0.5), rgba(225,225,225,0.5)), url('../../images/Pattern/pattern_product_bg.jpg');
} */

.slick-next:before {
    /* content: '→'; */
    color: black;
    /* background-color: #5a3636; */
}
.slick-prev:before {
    /* content: '→'; */
    color: black;
    /* background-color: #5a3636; */
}

.clearfix:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
}
.form_wrapper {
    background: #8b8b8b;
    width: 400px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 25px;
    margin: 3% auto 0;
    position: relative;
    z-index: 1;
    border-top: 5px solid #5a5151;
    box-shadow: 0 0 3px rgb(38 33 33 / 10%);
    transform-origin: 50% 0%;
    transform: scale3d(1, 1, 1);
    transition: none;

}
.form_wrapper h2 {
    font-size: 1.5em;
    line-height: 1.5em;
    margin: 0;
}
.form_wrapper .title_container {
    text-align: center;
    padding-bottom: 15px;
}
.form_wrapper h3 {
    font-size: 1.1em;
    font-weight: normal;
    line-height: 1.5em;
    margin: 0;
}
.form_wrapper label {
    font-size: 12px;
}
.form_wrapper .row {
    margin: 10px -15px;
}
.form_wrapper .row > div {
    padding: 0 15px;
    box-sizing: border-box;
}
.form_wrapper .col_half {
    width: 50%;
    float: left;
}
.form_wrapper .input_field {
    position: relative;
    margin-bottom: 20px;
}
.form_wrapper .input_field > span {
    position: absolute;
    left: 0;
    top: 0;
    color: #333;
    height: 100%;
    border-right: 1px solid #ccc;
    text-align: center;
    width: 30px;
}
.form_wrapper .input_field > span > i {
    padding-top: 10px;
}
.form_wrapper .textarea_field > span > i {
    padding-top: 10px;
}
.form_wrapper input[type="text"], .form_wrapper input[type="email"], .form_wrapper input[type="password"] {
    width: 100%;
    padding: 8px 10px 9px 35px;
    height: 35px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    outline: none;
    transition: all 0.3s ease-in-out;
}
.form_wrapper input[type="text"]:hover, .form_wrapper input[type="email"]:hover, .form_wrapper input[type="password"]:hover {
    background: #fafafa;
}
.form_wrapper input[type="text"]:focus, .form_wrapper input[type="email"]:focus, .form_wrapper input[type="password"]:focus {
    box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
    border: 1px solid #f5ba1a;
    background: #fafafa;
}
.form_wrapper input[type="submit"] {
    background: #323232;
    height: 45px;
    border-radius: 33px;
    line-height: 35px;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 1.1em;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
}
.form_wrapper input[type="submit"]:hover {
    background: #626262;
}
.form_wrapper input[type="submit"]:focus {
    background: #323232;
}
.form_wrapper input[type="checkbox"], .form_wrapper input[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.form_container .row .col_half.last {
    border-left: 1px solid #ccc;
}
.checkbox_option label {
    margin-right: 1em;
    position: relative;
}
.checkbox_option label:before {
    content: "";
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin-right: 0.5em;
    vertical-align: -2px;
    border: 2px solid #ccc;
    padding: 0.12em;
    background-color: transparent;
    background-clip: content-box;
    transition: all 0.2s ease;
}
.checkbox_option label:after {
    border-right: 2px solid #000;
    border-top: 2px solid #000;
    content: "";
    height: 20px;
    left: 2px;
    position: absolute;
    top: 7px;
    transform: scaleX(-1) rotate(135deg);
    transform-origin: left top;
    width: 7px;
    display: none;
}
.checkbox_option input:hover + label:before {
    border-color: #000;
}
.checkbox_option input:checked + label:before {
    border-color: #000;
}
.checkbox_option input:checked + label:after {

    display: block;
    width: 7px;
    height: 20px;
    border-color: #000;
}
.radio_option label {
    margin-right: 1em;
}
.radio_option label:before {
    content: "";
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin-right: 0.5em;
    border-radius: 100%;
    vertical-align: -3px;
    border: 2px solid #ccc;
    padding: 0.15em;
    background-color: transparent;
    background-clip: content-box;
    transition: all 0.2s ease;
}
.radio_option input:hover + label:before {
    border-color: #000;
}
.radio_option input:checked + label:before {
    background-color: #000;
    border-color: #000;
}
.select_option {
    position: relative;
    width: 100%;
}
.select_option select {
    display: inline-block;
    width: 100%;
    height: 35px;
    padding: 0px 15px;
    cursor: pointer;
    color: #7b7b7b;
    border: 1px solid #ccc;
    border-radius: 0;
    background: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.2s ease;
}

.select_option select:hover, .select_option select:focus {
    color: #000;
    background: #fafafa;
    border-color: #000;
    outline: none;
}
.select_arrow {
    position: absolute;
    top: calc(50% - 4px);
    right: 15px;
    width: 0;
    height: 0;
    pointer-events: none;
    border-width: 8px 5px 0 5px;
    border-style: solid;
    border-color: #7b7b7b transparent transparent transparent;
}
.select_option select:hover + .select_arrow, .select_option select:focus + .select_arrow {
    border-top-color: #000;
}
.credit {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 15px;
    color: #f5ba1a;
}
.credit a {
    color: #e1a70a;
}

@media (max-width: 600px) {
    .form_wrapper .col_half {
        width: 100%;
        float: none;
   }
    .bottom_row .col_half {
        width: 50%;
        float: left;
   }
    .form_container .row .col_half.last {
        border-left: none;
   }
    .remember_me {
        padding-bottom: 20px;
   }
}


.signup_btn{
    width: 300px;
    margin-left: 105px;
}
.login-block {
  background: #de6262; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient( to bottom, #ffffff, #1b1b1b );
  float: left;
  width: 100%;
  
}
.banner-sec {
  /* background: url(https://static.pexels.com/photos/33972/pexels-photo.jpg)
    no-repeat left bottom; */
  background-size: cover;
  min-height: 500px;
  border-radius: 0 10px 10px 0;
  padding: 0;
}

F .carousel-inner {
  border-radius: 0 10px 10px 0;
}
.carousel-caption {
  text-align: left;
  left: 5%;
}
.login-sec {
  padding: 50px 30px;
  position: relative;
}
.login-sec .copy-text {
  position: absolute;
  width: 80%;
  bottom: 20px;
  font-size: 13px;
  text-align: center;
}
.login-sec .copy-text i {
  color: #feb58a;
}
.login-sec .copy-text a {
  color: #e36262;
}
.login-sec h2 {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 30px;
  color: #8c8c8c;
}
.login-sec h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #909090;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}
.btn-login {
  background: #626262;
  color: #fff;
  font-weight: 600;
  width: 130px;
}
.banner-text {
  width: 70%;
  position: absolute;
  bottom: 40px;
  padding-left: 20px;
}
.banner-text h2 {
  color: #fff;
  font-weight: 600;
}
.banner-text h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #fff;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
}
.banner-text p {
  color: #fff;
}

.login_banner_div{
    width: 100%;
    height: 800px;
}
.login_banner_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.welcome_heading{
  font-weight: 700;
  color: dimgrey;
  font-family: 'Lobster', cursive;
}

.new_signup{
  font-size: 25px;
  color: dimgray;
  font-weight: bolder;
}

.powered_text{
  font-size: 30px;
  color: #1b1b1b;
  margin-top: 100px;
  font-weight: bolder;
  font-family: 'Baloo Tamma 2', cursive;
}
.user_card{
    background-color: #585454 !important;
    color: beige;
    font-size: 20px;
    font-weight: 700;

}

.user_img{
    position: relative;
}

.user_verified_badge{
    position: absolute;
    top: 37%;
    left: 62%;
}


.fa-badge-check{
    color: #458eff;
    margin-top: 10px;
}

.user_img_div{
    width: 100%;
    height: 100px;
}
.user_img{
    width: 100px;
    height: 100px;
    border-radius: 100px;
}

.matchuser_img_div{
    width: 100%;
    height: 100px;
    overflow: hidden;
}

.matchuser_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 200px;
}

.interested_user{
    font-size: 1.2rem;
    font-weight: 600;
    opacity: 0.8;
    color: rgb(56 55 55);
    font-family: 'Baloo Tamma 2', cursive;
}

.userName{
    font-size: 1.4rem;
    font-weight: 600;
    opacity: 0.9;
}

.user_profile_name{
    font-family: 'Baloo Tamma 2', cursive;
}
.form_input_field {
  margin: 10px;
}

.img_div_kyc {
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.img_kyc {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.kyc_container {
  box-shadow: 2px 0px 8px 2px grey;
  background: rgb(75, 180, 58);
  background: linear-gradient(
    90deg,
    rgba(75, 180, 58, 1) 1%,
    rgba(29, 253, 198, 1) 100%,
    rgba(69, 252, 84, 1) 100%
  );
}

.form_input_div {
  width: 50%;
  margin: auto;
}

.ghumfir_kyc_title {
  font-size: 40px;
  font-weight: 700;
  padding-top: 30px;
  /* font-family: "Baloo Tamma2"; */
  color: #6a6a6a;
}
.kyc_user_detail {
  font-size: 30px;
  color: #6a6a6a;
}

.user_kyc_profession {
  font-size: 30px;
  color: #6a6a6a;
}

.user_kyc_document_details {
  font-size: 30px;
  color: #6a6a6a;
}

.user_kyc_document_photos {
  font-size: 30px;
  color: #6a6a6a;
}

.custom_form_btn_div {
  width: 50%;
  margin: auto;
}
.custom_kyc_btn {
  margin-left: 30%;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid grey !important;
  border-radius: 50px !important;
  background: rgb(240,47,219) !important;
  background: linear-gradient(90deg, rgba(240,47,219,1) 10%, rgba(58,64,180,1) 100%, rgba(29,41,253,1) 100%) !important;
  
}
.custom_kyc_link {
  margin-left: 15px;
  text-decoration: none;
  padding: 8px;
  border-radius: 50px;
  color: black;
  background: rgb(151, 180, 58) !important;
  background: linear-gradient(
    90deg,
    rgba(151, 180, 58, 1) 1%,
    rgba(29, 41, 253, 1) 100%,
    rgba(240, 218, 47, 1) 100%
  ) !important;
}

.custom_kyc_link:hover{
    color: blanchedalmond;
}

input[type="text"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}
input[type="number"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}
input[type="date"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}
input[type="file"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}

.interest_container{
    border: 2px solid darkgrey;
    padding-left: 464px;
    padding-top: 30px;
    padding-bottom: 30px;

    /* background: url('https://ghumfir003.pythonanywhere.com/images/logo.png') no-repeat no-repeat center ; */
}
.interest_data{
    color: #544b4b;
    font-size: 29px;
    font-weight: 700;
    font-family: 'Baloo 2';
}
.interest_box{
    margin-left: 320px;
}
input[type='checkbox']{
    background-color: rgb(245, 245, 245);
    text-align: center;
    border: 2px solid rgb(97, 97, 97);
    border-radius: 100px;

}
.interest_title{
    font-family: 'Baloo 2';
    color: #544b4b;
    margin-right: 120px;
    margin-bottom: 40px;
}
/* .interest_location{
    
} */
