.user_card{
    background-color: #585454 !important;
    color: beige;
    font-size: 20px;
    font-weight: 700;

}

.user_img{
    position: relative;
}

.user_verified_badge{
    position: absolute;
    top: 37%;
    left: 62%;
}


.fa-badge-check{
    color: #458eff;
    margin-top: 10px;
}
