.overflow{
    width: 100%;
    height: 200px;
    overflow: hidden;
}
.place_card{
    background-color: rgb(216, 216, 216) !important;
}

.places_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.place_header{
    font-size: 2.4rem;
    /* font-weight: 700; */
    font-family: 'Lobster', cursive;
    color: #000;
    opacity: 0.8;
}