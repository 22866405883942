
.clearfix:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
}
.form_wrapper {
    background: #8b8b8b;
    width: 400px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 25px;
    margin: 3% auto 0;
    position: relative;
    z-index: 1;
    border-top: 5px solid #5a5151;
    box-shadow: 0 0 3px rgb(38 33 33 / 10%);
    transform-origin: 50% 0%;
    transform: scale3d(1, 1, 1);
    transition: none;

}
.form_wrapper h2 {
    font-size: 1.5em;
    line-height: 1.5em;
    margin: 0;
}
.form_wrapper .title_container {
    text-align: center;
    padding-bottom: 15px;
}
.form_wrapper h3 {
    font-size: 1.1em;
    font-weight: normal;
    line-height: 1.5em;
    margin: 0;
}
.form_wrapper label {
    font-size: 12px;
}
.form_wrapper .row {
    margin: 10px -15px;
}
.form_wrapper .row > div {
    padding: 0 15px;
    box-sizing: border-box;
}
.form_wrapper .col_half {
    width: 50%;
    float: left;
}
.form_wrapper .input_field {
    position: relative;
    margin-bottom: 20px;
}
.form_wrapper .input_field > span {
    position: absolute;
    left: 0;
    top: 0;
    color: #333;
    height: 100%;
    border-right: 1px solid #ccc;
    text-align: center;
    width: 30px;
}
.form_wrapper .input_field > span > i {
    padding-top: 10px;
}
.form_wrapper .textarea_field > span > i {
    padding-top: 10px;
}
.form_wrapper input[type="text"], .form_wrapper input[type="email"], .form_wrapper input[type="password"] {
    width: 100%;
    padding: 8px 10px 9px 35px;
    height: 35px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    outline: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.form_wrapper input[type="text"]:hover, .form_wrapper input[type="email"]:hover, .form_wrapper input[type="password"]:hover {
    background: #fafafa;
}
.form_wrapper input[type="text"]:focus, .form_wrapper input[type="email"]:focus, .form_wrapper input[type="password"]:focus {
    -webkit-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
    -moz-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
    box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
    border: 1px solid #f5ba1a;
    background: #fafafa;
}
.form_wrapper input[type="submit"] {
    background: #323232;
    height: 45px;
    border-radius: 33px;
    line-height: 35px;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 1.1em;
    margin-bottom: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.form_wrapper input[type="submit"]:hover {
    background: #626262;
}
.form_wrapper input[type="submit"]:focus {
    background: #323232;
}
.form_wrapper input[type="checkbox"], .form_wrapper input[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.form_container .row .col_half.last {
    border-left: 1px solid #ccc;
}
.checkbox_option label {
    margin-right: 1em;
    position: relative;
}
.checkbox_option label:before {
    content: "";
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin-right: 0.5em;
    vertical-align: -2px;
    border: 2px solid #ccc;
    padding: 0.12em;
    background-color: transparent;
    background-clip: content-box;
    transition: all 0.2s ease;
}
.checkbox_option label:after {
    border-right: 2px solid #000;
    border-top: 2px solid #000;
    content: "";
    height: 20px;
    left: 2px;
    position: absolute;
    top: 7px;
    transform: scaleX(-1) rotate(135deg);
    transform-origin: left top;
    width: 7px;
    display: none;
}
.checkbox_option input:hover + label:before {
    border-color: #000;
}
.checkbox_option input:checked + label:before {
    border-color: #000;
}
.checkbox_option input:checked + label:after {

    display: block;
    width: 7px;
    height: 20px;
    border-color: #000;
}
.radio_option label {
    margin-right: 1em;
}
.radio_option label:before {
    content: "";
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin-right: 0.5em;
    border-radius: 100%;
    vertical-align: -3px;
    border: 2px solid #ccc;
    padding: 0.15em;
    background-color: transparent;
    background-clip: content-box;
    transition: all 0.2s ease;
}
.radio_option input:hover + label:before {
    border-color: #000;
}
.radio_option input:checked + label:before {
    background-color: #000;
    border-color: #000;
}
.select_option {
    position: relative;
    width: 100%;
}
.select_option select {
    display: inline-block;
    width: 100%;
    height: 35px;
    padding: 0px 15px;
    cursor: pointer;
    color: #7b7b7b;
    border: 1px solid #ccc;
    border-radius: 0;
    background: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.2s ease;
}

.select_option select:hover, .select_option select:focus {
    color: #000;
    background: #fafafa;
    border-color: #000;
    outline: none;
}
.select_arrow {
    position: absolute;
    top: calc(50% - 4px);
    right: 15px;
    width: 0;
    height: 0;
    pointer-events: none;
    border-width: 8px 5px 0 5px;
    border-style: solid;
    border-color: #7b7b7b transparent transparent transparent;
}
.select_option select:hover + .select_arrow, .select_option select:focus + .select_arrow {
    border-top-color: #000;
}
.credit {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 15px;
    color: #f5ba1a;
}
.credit a {
    color: #e1a70a;
}

@media (max-width: 600px) {
    .form_wrapper .col_half {
        width: 100%;
        float: none;
   }
    .bottom_row .col_half {
        width: 50%;
        float: left;
   }
    .form_container .row .col_half.last {
        border-left: none;
   }
    .remember_me {
        padding-bottom: 20px;
   }
}


.signup_btn{
    width: 300px;
    margin-left: 105px;
}