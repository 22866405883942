/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */
/* .home_container_fluid{
    padding-top: 0px;
}
.home_container{
}
.home_nav_icon{
    text-align: left;
    font-family: 'Lobster', cursive;
    font-size: 45px;
    color: rgb(68, 68, 68);
}

.home_login{
    text-align: right;
    width: 110px !important;
}
.header_login_btn{
    text-align: right;
}
.home_bg{
    background: linear-gradient(rgba(225,225,225,0.5), rgba(225,225,225,0.5)), url('../../images/Pattern/pattern_product_bg.jpg');
} */

.slick-next:before {
    /* content: '→'; */
    color: black;
    /* background-color: #5a3636; */
}
.slick-prev:before {
    /* content: '→'; */
    color: black;
    /* background-color: #5a3636; */
}